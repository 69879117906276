<template>
  <div class="h-100">
    <fetching-data-spinner
      :loading="fetching"
      :error="error"
      :loading-title="$t('help.preparing-help')"
      enable-retry
      @retry="fetchSections"
    />
    <template v-if="!fetching">
      <div
        v-for="section in sections"
        :key="section.id"
        class="mb-1"
      >
        <h3 class="mb-1">
          {{ getTranslated(section) }}
        </h3>

        <b-row
          class="blog-list-wrapper"
        >
          <b-col
            v-for="question in section.questions"
            :key="question.id"
            md="4"
          >
            <b-card
              tag="article"
              no-body
              class="cursor-pointer"
              @click="openModal(question)"
            >
              <b-img
                :src="question.video"
                class="card-img-top"
              />
              <b-card-body>
                <b-card-title>
                  {{ getTranslated(question,'question') }}
                </b-card-title>
                <div class="my-1 py-25">
                  <b-badge
                    pill
                    class="mr-75"
                    variant="primary"
                  >
                    {{ section.slug }}
                  </b-badge>
                </div>
                <hr>
                <div class="float-right">
                  <b-button
                    variant="flat-primary"
                    @click="openModal(question)"
                  >
                    قراءة المنشور
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

        </b-row>
      </div>
    </template>
    <help-post-modal
      v-model="showPostModal"
      :question="selectedPost"
    />
  </div>
</template>

<script>

import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import HelpAPI from '@/api/admin/help-api'
import {
  BRow, BCol, BCard, BCardTitle, BImg, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import HelpPostModal from '@/views/help/components/PostModal'

export default {
  components: {
    HelpPostModal,
    FetchingDataSpinner,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    BCardTitle,
    BBadge,
    BImg,
  },
  data() {
    return {
      fetching: false,
      error: null,
      sections: [],
      showPostModal: false,
      selectedPost: {},
    }
  },
  async mounted() {
    await this.fetchSections()
  },
  methods: {
    openModal(post) {
      this.showPostModal = true
      this.selectedPost = post
    },
    fetchSections() {
      this.fetching = true
      this.error = null

      HelpAPI.list()
        .then(sections => { this.sections = sections })
        .catch(error => { this.error = error })
        .finally(() => { this.fetching = false })
    },
    getTranslated(item, attr = 'name') {
      return item.locales[0][attr] ?? ''
    },
  },

}
</script>
